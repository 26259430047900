<template>
  <loading v-if="loading" />
  <no-results
    v-else-if="!changeId"
    title="No results"
    message="There are no changes."
    style="margin: auto;"
  />
  <div v-else>
    <change-snapshot :site-id="siteId" :change-id="changeId" />
  </div>
</template>

<script>
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query
} from "@firebase/firestore";
export default {
  name: "SiteChangeLatestSnapshot",
  components: {
    "change-snapshot": () => import("@shared/sites/_siteChangeSnapshot.vue")
  },
  props: {
    siteId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      changeId: 0,
      loading: true
    };
  },
  created() {
    const changesRef = query(
      collection(this.$firestore, `sites/${this.siteId}/changes`),
      orderBy("timestamp", "desc"),
      limit(1)
    );

    getDocs(changesRef)
      .then(changes => {
        this.changeId = !changes.empty ? changes.docs[0].id : null;
        this.loading = false;
      })
      .catch(() => {
        this.$toast.open({
          message: "Error getting current state",
          position: "is-bottom",
          type: "is-danger",
          queue: false
        });
        this.loading = false;
      });
  }
};
</script>
